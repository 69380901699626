<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Pobierz pliki</b> w tym miejscu możesz zarządzać plikami, które będą
        widoczne dla uczestników Programu Lojalnościowego
      </div>
    </b-alert>

    <b-row>
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Pliki do pobrania'">
          <template v-slot:toolbar-action v-if="role === 'ROLE_ADMIN'">
            <div>
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="showModal()"
              >
                dodaj plik <b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <div>
              <div class="card-body pt-2 pb-0" v-if="state === 'loaded'">
                <!--begin::Table-->
                <div class="table-responsive">
                  <table class="table table-borderless table-vertical-center">
                    <thead>
                      <tr>
                        <th class="p-0" style="width: 50px"></th>
                        <th class="p-0" style="min-width: 150px"></th>
                        <th class="p-0" style="min-width: 140px"></th>
                        <th class="p-0" style="min-width: 40px"></th>
                        <th class="p-0" style="min-width: 40px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(file, i) in files">
                        <tr v-bind:key="i">
                          <td class="pl-0 py-5">
                            <div
                              class="symbol symbol-45 mr-2"
                              v-bind:class="`symbol-light-${item.class}`"
                            >
                              <span class="symbol-label">
                                <span
                                  class="svg-icon svg-icon-2x"
                                  v-bind:class="`svg-icon-${item.class}`"
                                >
                                  <inline-svg :src="item.svg" />
                                </span>
                              </span>
                            </div>
                          </td>
                          <td class="pl-0">
                            <a
                              href="#"
                              class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              >{{ file.name }}</a
                            >
                          </td>
                          <td class="text-right">
                            <span class="text-muted font-weight-bold">{{
                              file.date
                            }}</span>
                          </td>
                          <td class="text-right pr-0">
                            <span
                              class="text-dark-75 font-weight-bolder font-size-lg"
                              >{{ convertToMB(file.size) }} MB</span
                            >
                          </td>
                          <td
                            class="text-right pr-0"
                            v-if="role === 'ROLE_ADMIN'"
                          >
                            <b-button
                              v-b-tooltip.hover.topright
                              title="Pobierz"
                              variant="primary"
                              size="sm"
                              class="mb-1"
                              @click="downloadFile(file.dbfile)"
                            >
                              <b-icon
                                icon="download"
                                aria-hidden="true"
                              ></b-icon>
                            </b-button>

                            <b-button
                              v-b-tooltip.hover.topright
                              title="Usuń"
                              variant="danger"
                              size="sm"
                              class="mb-1"
                              @click="deleteFile(file.id)"
                            >
                              <b-icon icon="trash" aria-hidden="true"></b-icon>
                            </b-button>
                          </td>

                          <td
                            class="text-right pr-0"
                            v-if="role === 'ROLE_USER'"
                          >
                            <b-button
                              v-b-tooltip.hover.topright
                              title="Pobierz"
                              variant="primary"
                              size="sm"
                              class="mb-1"
                              @click="downloadFile(file.dbfile)"
                            >
                              <b-icon
                                icon="download"
                                aria-hidden="true"
                              ></b-icon>
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--end::table-->
              </div>
              <!--begin::Body-->
              <div class="d-flex justify-content-center mb-3" v-else>
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </b-row>

    <AddFileModal
      :id="id"
      :title="'Nowy plik zostanie dodany'"
      @refreshData="onSave"
    >
    </AddFileModal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AddFileModal from "@/view/pages/allShared/files/AddFileModal.vue";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "Files",

  data() {
    return {
      id: "addFile",
      state: "loading",
      item: {
        svg: "media/svg/files/pdf.svg",
        class: "primary"
      },
      files: {}
    };
  },
  components: {
    KTCodePreview,
    AddFileModal
  },
  methods: {
    showModal() {
      this.$bvModal.show(this.id);
    },

    getFiles() {
      ApiService.get("/files").then(response => {
        this.files = response.data;
        this.state = "loaded";
      });
    },

    deleteFile(id) {
      Swal.fire({
        title: "Czy napewno chcesz usunąć wpis?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009136",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.value) {
          ApiService.delete("/file/" + id).then(() => {
            this.getFiles();
          });
        }
      });
    },

    onSave(files) {
      this.files = files;
    },

    downloadFile(apifile) {
      var byteCharacters = atob(apifile);
      var byteNumbers = new Array(byteCharacters.length);

      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: "application/pdf;base64" });
      var fileURL = URL.createObjectURL(file);

      window.open(fileURL);
    },

    convertToMB(size) {
      return Math.round((size / 1000000) * 100) / 100;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Zarządzanie plikami" },
      { title: "" }
    ]);

    this.getFiles();
  },
  computed: mapState({
    role: state => (state.auth.user ? state.auth.user.role : "ROLE_ANONYMOUS")
  })
};
</script>
