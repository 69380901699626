<template>
  <div>
    <!-- details modal  -->
    <b-modal
      size="lg"
      scrollable
      :id="id"
      :title="title"
      @ok="handleOk"
      @shown="onShown"
      @show="resetModal"
    >
      <div class="d-block text-center"></div>

      <form class="form" novalidate="novalidate" id="add_file_form">
        <b-form-group id="fieldset-1" label="Data dodania" label-for="date">
          <b-form-datepicker v-model="date" name="date"> </b-form-datepicker>
        </b-form-group>

        <b-form-group id="fieldset-1" label="Nazwa" label-for="name">
          <b-form-input v-model="name" name="name" placeholder="wprowadź nazwę">
          </b-form-input>
        </b-form-group>

        <b-form-group
          id="fieldset-1"
          label="Wybierz plik z dysku"
          label-for="file"
        >
          <b-form-file
            accept="application/pdf"
            v-model="file"
            name="file"
            :state="Boolean(file)"
            placeholder="wybierz plik lub upuść tutaj ..."
            drop-placeholder="upuść plik tutaj ..."
          ></b-form-file>
          <div class="mt-3">Wybrałeś: {{ file ? file.name : "" }}</div>
        </b-form-group>
      </form>
      <b-progress
        height="20px"
        v-if="showProgress"
        :value="uploadProgress"
        show-progress
        class="mb-2"
      ></b-progress>
    </b-modal>
    <!-- end of details modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  name: "AddFileModal",
  props: {
    id: String,
    title: String
  },
  data() {
    return {
      date: new Date().toISOString(),
      name: "",
      file: null,
      uploadProgress: 0,
      showProgress: false
    };
  },
  mounted() {},
  methods: {
    onShown() {
      const order_form = KTUtil.getById("add_file_form");

      this.orderForm = formValidation(order_form, {
        fields: {
          date: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać datę"
              },
              blank: {}
            }
          },
          name: {
            validators: {
              notEmpty: {
                message: "Proszę wpisać nazwę"
              },
              blank: {}
            }
          },
          file: {
            validators: {
              notEmpty: {
                message: "Proszę wybrać plik"
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.orderForm.validate().then(v => {
        if (v === "Valid") {
          this.showProgress = true;
          const formData = new FormData();

          formData.append("date", this.date);
          formData.append("name", this.name);
          formData.append("dbfile", this.file);

          ApiService.post("/file/add", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: uploadEvent => {
              this.uploadProgress = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100
              );
            }
          }).then(() => {
            this.refreshFiles();
            this.$nextTick(() => {
              this.$bvModal.hide(this.$props.id);
            });
          });
        }
      });
    },

    refreshFiles() {
      ApiService.get("/files").then(response => {
        const files = response.data;
        this.$emit("refreshData", files);
      });
    },

    resetModal() {
      this.date = new Date().toISOString().slice(0, 10);
      this.name = "";
      this.file = null;
      this.uploadProgress = 0;
      this.showProgress = false;
    }
  }
};
</script>
